{
  "name": "pagopa-api-config-fe",
  "version": "1.36.9-1-PAGOPA-1622-sviluppo-api-config-gestione-flag-stand-in-per-wsdl",
  "license": "MIT",
  "scripts": {
    "envconfig": "chmod +x envconfig.sh && . ./envconfig.sh && chmod +x version.sh && ./version.sh",
    "prebuild": "npm-run-all prebuild:* && chmod +x version.sh && ./version.sh",
    "prebuild:clean": "shx rm -rf build",
    "prebuild:tsc": "tsc --noEmit",
    "build": "parcel build src/*.html --dist-dir build/release --public-url ./ --no-optimize && cp ./public/version.json ./build/release",
    "lint": "eslint -f json -o eslint-report.json . -c .eslintrc.js --ext .ts,.tsx --cache",
    "lint:local": "eslint . -c .eslintrc.js --ext .ts,.tsx --cache",
    "lint-autofix": "eslint . -c .eslintrc.js --ext .ts,.tsx --fix",
    "prestart": "npm-run-all envconfig",
    "start": "parcel --port 3000 src/index.html --dist-dir build/debug",
    "pretest": "npm-run-all envconfig",
    "test": "jest --coverage --ci --testResultsProcessor jest-sonar-reporter",
    "test:local": "jest --coverage --ci",
    "generate:api": "rm -f swagger.json && wget https://raw.githubusercontent.com/pagopa/pagopa-api-config/main/openapi/swagger.json && sed -i 's/\\\\/\\\\\\\\/g' swagger.json && python3 ./convert.py && gen-api-models --api-spec swagger.json --no-strict --out-dir generated/api --request-types --response-decoders --client",
    "macosx:generate:api": "rm -f swagger.json && wget https://raw.githubusercontent.com/pagopa/pagopa-api-config/main/openapi/swagger.json && sed -i '' 's/\\\\/\\\\\\\\/g' swagger.json && python3 ./convert.py && gen-api-models --api-spec swagger.json --no-strict --out-dir generated/api --request-types --response-decoders --client",
    "generate": "npm-run-all generate:*",
    "prettify": "prettier --write \"./**/*.{ts,tsx}\""
  },
  "dependencies": {
    "@azure/msal-browser": "^2.19.0",
    "@azure/msal-react": "^1.1.1",
    "@pagopa/ts-commons": "^10.2.1",
    "axios": "^0.28.0",
    "bootstrap": "^4.5.3",
    "classnames": "^2.2.6",
    "date-fns": "^2.16.1",
    "enzyme": "^3.11.0",
    "enzyme-adapter-react-16": "^1.15.6",
    "fp-ts": "^2.11.3",
    "generated": "^0.0.3",
    "i18next": "^19.8.4",
    "ibantools": "^4.1.3",
    "io-ts": "^2.2.13",
    "lodash.debounce": "^4.0.8",
    "react": "17.0.1",
    "react-bootstrap": "^1.6.4",
    "react-dom": "^17.0.2",
    "react-i18next": "^11.7.3",
    "react-icons": "^4.3.1",
    "react-markdown": "^8.0.0",
    "react-router-dom": "^5.3.0",
    "react-select": "^5.3.0",
    "react-test-renderer": "^17.0.2",
    "react-toastify": "^8.0.3",
    "typeface-lora": "^1.1.13",
    "typeface-roboto-mono": "^1.1.13",
    "typeface-titillium-web": "^1.1.13"
  },
  "devDependencies": {
    "@babel/core": "^7.15.8",
    "@babel/preset-env": "^7.15.8",
    "@babel/preset-react": "^7.14.5",
    "@pagopa/eslint-config": "^1.1.1",
    "@pagopa/openapi-codegen-ts": "^10.0.3",
    "@parcel/packager-raw-url": "2.3.2",
    "@parcel/transformer-sass": "^2.2.0",
    "@parcel/transformer-webmanifest": "2.3.2",
    "@testing-library/jest-dom": "^5.14.1",
    "@testing-library/react": "^12.1.2",
    "@types/classnames": "^2.2.11",
    "@types/enzyme": "^3.10.12",
    "@types/enzyme-adapter-react-16": "^1.0.6",
    "@types/jest": "^27.0.2",
    "@types/lodash.debounce": "^4.0.6",
    "@types/node": "^14.14.9",
    "@types/node-fetch": "^2.5.7",
    "@types/react": "^16.9.56",
    "@types/react-dom": "^17.0.10",
    "@types/react-router-dom": "^5.1.6",
    "@typescript-eslint/eslint-plugin": "^4.28.4",
    "@typescript-eslint/parser": "^4.28.4",
    "babel-jest": "^27.2.5",
    "babel-plugin-macros": "^3.1.0",
    "bootstrap-italia": "^1.4.3",
    "buffer": "^6.0.3",
    "cssnano": "^5.0.8",
    "danger-plugin-digitalcitizenship": "^1.0.0",
    "dotenv": "^8.2.0",
    "eslint": "^7.31.0",
    "eslint-config-prettier": "^8.3.0",
    "eslint-plugin-functional": "^3.2.1",
    "eslint-plugin-import": "^2.23.4",
    "eslint-plugin-prettier": "^3.3.1",
    "eslint-plugin-react": "^7.24.0",
    "eslint-plugin-react-hooks": "^4.2.0",
    "eslint-plugin-sonarjs": "^0.9.1",
    "italia-ts-commons": "^8.6.0",
    "jest": "^27.0.6",
    "jest-sonar-reporter": "^2.0.0",
    "jquery": "^3.6.0",
    "npm-run-all": "^4.1.5",
    "os-browserify": "^0.3.0",
    "parcel": "^2.2.0",
    "parcel-transformer-markdown": "^2.1.0",
    "path-browserify": "^1.0.1",
    "prettier": "2.1.0",
    "process": "^0.11.10",
    "querystring-es3": "^0.2.1",
    "raw.macro": "^0.4.2",
    "sass": "^1.42.1",
    "shx": "^0.3.3",
    "ts-jest": "^27.0.6",
    "typescript": "^4.1.2",
    "url": "^0.11.0",
    "util": "^0.12.4",
    "whatwg-fetch": "^3.6.2"
  },
  "resolutions": {
    "@babel/preset-env": "7.13.8",
    "ansi-regex": "^5.0.1",
    "json-schema": "^0.4.0",
    "node-fetch": "^2.6.1",
    "validator": "^13.7.0",
    "postcss": "^8.3.0",
    "node-forge": "^1.0.0"
  }
}
